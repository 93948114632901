/* styles */

* {
	scrollbar-width: thin;
	scrollbar-color: #fa5a00 #0f2955;
}

*::-webkit-scrollbar {
	width: 8px;
}

*::-webkit-scrollbar-track {
	background: #0f2955;
}

*::-webkit-scrollbar-thumb {
	background-color: #fa5a00;
}

html {
	height: 100%;
}

html * {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-size-adjust: 100%;
}

body {
	height: 100%;
	min-width: 320px;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5;
	cursor: default;
	position: relative;
}

/* page */

.main_wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	min-height: 100%;
	background: #051130 url("../../shared/img/bg_desktop.jpg") center no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	overflow: hidden;
}

.logo {
	display: block;
	position: absolute;
	top: 30px;
	left: 50%;
	margin-left: -105px;
}

.content_wrapper {
	margin: 0 auto;
	padding: 90px 20px 30px;
	width: 100%;
	max-width: 1440px;
	position: relative;
}

.main_title {
	margin: 0 auto 40px;
	width: 100%;
	max-width: 700px;
	font-weight: 900;
	font-style: italic;
	font-size: 60px;
	line-height: 1.1;
	letter-spacing: 1.2px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
}

.photo_block {
	position: relative;
}

.photo_block .photo {
	position: absolute;
	top: -40px;
}

.photo_block .photo1 {
	left: -60px;
}

.photo_block .photo2 {
	right: -85px;
}

.spin_count_wrapper {
	margin: 0 0 40px;
	font-size: 20px;
	line-height: 26px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
}

.spin_count_block {
	padding: 11px 30px;
	-webkit-border-radius: 23px;
	-moz-border-radius: 23px;
	border-radius: 23px;
	background: #071437;
}

.spin_count_block .count {
	font-weight: 700;
}

.wheel_inner {
	margin: 0 auto;
	width: 597px;
	height: 597px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background: #081339 url("../../shared/img/wheel_bg.png") center no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	-webkit-box-shadow: 0 0 30px 0 rgba(255, 121, 11, 0.15);
	-moz-box-shadow: 0 0 30px 0 rgba(255, 121, 11, 0.15);
	box-shadow: 0 0 30px 0 rgba(255, 121, 11, 0.15);
	position: relative;
}

@-webkit-keyframes glow {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@-moz-keyframes glow {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@-o-keyframes glow {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes glow {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.wheel_inner:before {
	display: block;
	content: "";
	width: 615px;
	height: 595px;
	background: url("../../shared/img/wheel_glows.png") center no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	-webkit-animation: glow 0.6s infinite linear;
	-moz-animation: glow 0.6s infinite linear;
	-o-animation: glow 0.6s infinite linear;
	animation: glow 0.6s infinite linear;
	position: absolute;
	top: 1px;
	left: -9px;
}

.wheel_block {
	width: 527px;
	height: 527px;
	position: absolute;
	bottom: 33px;
	left: 35px;
}

@-webkit-keyframes rotate1 {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}

	100% {
		-webkit-transform: rotate(2160deg);
		transform: rotate(2160deg);
	}
}

@-moz-keyframes rotate1 {
	0% {
		-moz-transform: rotate(0);
		transform: rotate(0);
	}

	100% {
		-moz-transform: rotate(2160deg);
		transform: rotate(2160deg);
	}
}

@-o-keyframes rotate1 {
	0% {
		-o-transform: rotate(0);
		transform: rotate(0);
	}

	100% {
		-o-transform: rotate(2160deg);
		transform: rotate(2160deg);
	}
}

@keyframes rotate1 {
	0% {
		-webkit-transform: rotate(0);
		-moz-transform: rotate(0);
		-o-transform: rotate(0);
		transform: rotate(0);
	}

	100% {
		-webkit-transform: rotate(2160deg);
		-moz-transform: rotate(2160deg);
		-o-transform: rotate(2160deg);
		transform: rotate(2160deg);
	}
}

@-webkit-keyframes rotate2 {
	0% {
		-webkit-transform: rotate(2160deg);
		transform: rotate(2160deg);
	}

	100% {
		-webkit-transform: rotate(4248deg);
		transform: rotate(4248deg);
	}
}

@-moz-keyframes rotate2 {
	0% {
		-moz-transform: rotate(2160deg);
		transform: rotate(2160deg);
	}

	100% {
		-moz-transform: rotate(4248deg);
		transform: rotate(4248deg);
	}
}

@-o-keyframes rotate2 {
	0% {
		-o-transform: rotate(2160deg);
		transform: rotate(2160deg);
	}

	100% {
		-o-transform: rotate(4248deg);
		transform: rotate(4248deg);
	}
}

@keyframes rotate2 {
	0% {
		-webkit-transform: rotate(2160deg);
		-moz-transform: rotate(2160deg);
		-o-transform: rotate(2160deg);
		transform: rotate(2160deg);
	}

	100% {
		-webkit-transform: rotate(4248deg);
		-moz-transform: rotate(4248deg);
		-o-transform: rotate(4248deg);
		transform: rotate(4248deg);
	}
}

.wheel_wrapper .rotate1 {
	-webkit-animation: rotate1 5s cubic-bezier(0, .8, .26, .99) forwards;
	-moz-animation: rotate1 5s cubic-bezier(0, .8, .26, .99) forwards;
	-o-animation: rotate1 5s cubic-bezier(0, .8, .26, .99) forwards;
	animation: rotate1 5s cubic-bezier(0, .8, .26, .99) forwards;
}

.wheel_wrapper .rotate2 {
	-webkit-animation: rotate2 5s cubic-bezier(0, .8, .26, .99) forwards;
	-moz-animation: rotate2 5s cubic-bezier(0, .8, .26, .99) forwards;
	-o-animation: rotate2 5s cubic-bezier(0, .8, .26, .99) forwards;
	animation: rotate2 5s cubic-bezier(0, .8, .26, .99) forwards;
}

.wheel_block .wheel {
	display: block;
	width: 100%;
	height: auto;
	position: absolute;
	top: 0;
	left: 0;
}

.wheel_block .wheel_active {
	opacity: 0;
}

.wheel_block .wheel_active.show {
	opacity: 1;
}

.wheel_inner .marker {
	display: block;
	width: 44px;
	height: auto;
	position: absolute;
	top: -4px;
	left: 50%;
	margin-left: -22px;
}

.wheel_inner .spin_button {
	width: 168px;
	height: 168px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background: #ff4700;
	font-weight: 900;
	font-size: 26px;
	line-height: 168px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -84px;
	margin-left: -84px;
	z-index: 99;
}

.wheel_inner .spin_button:hover {
	background: #fc5c1e;
}

.wheel_inner .spin_button:active {
	-webkit-transform: scale(0.99);
	-moz-transform: scale(0.99);
	-ms-transform: scale(0.99);
	-o-transform: scale(0.99);
	transform: scale(0.99);
}

.wheel_inner .spin_button.disabled {
	background: #fc5c1e;
	color: rgba(255, 255, 255, 0.4);
	cursor: default;
}

.wheel_inner .spin_button.disabled:active {
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none;
}

/* *** */

@-webkit-keyframes object1 {
	from {
		-webkit-transform: rotate(180deg) translateX(20px) rotate(-180deg);
		transform: rotate(180deg) translateX(20px) rotate(-180deg);
	}

	to {
		-webkit-transform: rotate(540deg) translateX(20px) rotate(-540deg);
		transform: rotate(540deg) translateX(20px) rotate(-540deg);
	}
}

@-moz-keyframes object1 {
	from {
		-moz-transform: rotate(180deg) translateX(20px) rotate(-180deg);
		transform: rotate(180deg) translateX(20px) rotate(-180deg);
	}

	to {
		-moz-transform: rotate(540deg) translateX(20px) rotate(-540deg);
		transform: rotate(540deg) translateX(20px) rotate(-540deg);
	}
}

@-o-keyframes object1 {
	from {
		-o-transform: rotate(180deg) translateX(20px) rotate(-180deg);
		transform: rotate(180deg) translateX(20px) rotate(-180deg);
	}

	to {
		-o-transform: rotate(540deg) translateX(20px) rotate(-540deg);
		transform: rotate(540deg) translateX(20px) rotate(-540deg);
	}
}

@keyframes object1 {
	from {
		-webkit-transform: rotate(180deg) translateX(20px) rotate(-180deg);
		-moz-transform: rotate(180deg) translateX(20px) rotate(-180deg);
		-o-transform: rotate(180deg) translateX(20px) rotate(-180deg);
		transform: rotate(180deg) translateX(20px) rotate(-180deg);
	}

	to {
		-webkit-transform: rotate(540deg) translateX(20px) rotate(-540deg);
		-moz-transform: rotate(540deg) translateX(20px) rotate(-540deg);
		-o-transform: rotate(540deg) translateX(20px) rotate(-540deg);
		transform: rotate(540deg) translateX(20px) rotate(-540deg);
	}
}

@-webkit-keyframes object2 {
	from {
		-webkit-transform: rotate(180deg) translateX(15px) rotate(-180deg);
		transform: rotate(180deg) translateX(15px) rotate(-180deg);
	}

	to {
		-webkit-transform: rotate(540deg) translateX(15px) rotate(-540deg);
		transform: rotate(540deg) translateX(15px) rotate(-540deg);
	}
}

@-moz-keyframes object2 {
	from {
		-moz-transform: rotate(180deg) translateX(15px) rotate(-180deg);
		transform: rotate(180deg) translateX(15px) rotate(-180deg);
	}

	to {
		-moz-transform: rotate(540deg) translateX(15px) rotate(-540deg);
		transform: rotate(540deg) translateX(15px) rotate(-540deg);
	}
}

@-o-keyframes object2 {
	from {
		-o-transform: rotate(180deg) translateX(15px) rotate(-180deg);
		transform: rotate(180deg) translateX(15px) rotate(-180deg);
	}

	to {
		-o-transform: rotate(540deg) translateX(15px) rotate(-540deg);
		transform: rotate(540deg) translateX(15px) rotate(-540deg);
	}
}

@keyframes object2 {
	from {
		-webkit-transform: rotate(180deg) translateX(15px) rotate(-180deg);
		-moz-transform: rotate(180deg) translateX(15px) rotate(-180deg);
		-o-transform: rotate(180deg) translateX(15px) rotate(-180deg);
		transform: rotate(180deg) translateX(15px) rotate(-180deg);
	}

	to {
		-webkit-transform: rotate(540deg) translateX(15px) rotate(-540deg);
		-moz-transform: rotate(540deg) translateX(15px) rotate(-540deg);
		-o-transform: rotate(540deg) translateX(15px) rotate(-540deg);
		transform: rotate(540deg) translateX(15px) rotate(-540deg);
	}
}

@-webkit-keyframes object3 {
	from {
		-webkit-transform: rotate(180deg) translateX(10px) rotate(-180deg);
		transform: rotate(180deg) translateX(10px) rotate(-180deg);
	}

	to {
		-webkit-transform: rotate(-540deg) translateX(10px) rotate(540deg);
		transform: rotate(-540deg) translateX(10px) rotate(540deg);
	}
}

@-moz-keyframes object3 {
	from {
		-moz-transform: rotate(180deg) translateX(10px) rotate(-180deg);
		transform: rotate(180deg) translateX(10px) rotate(-180deg);
	}

	to {
		-moz-transform: rotate(-540deg) translateX(10px) rotate(540deg);
		transform: rotate(-540deg) translateX(10px) rotate(540deg);
	}
}

@-o-keyframes object3 {
	from {
		-o-transform: rotate(180deg) translateX(10px) rotate(-180deg);
		transform: rotate(180deg) translateX(10px) rotate(-180deg);
	}

	to {
		-o-transform: rotate(-540deg) translateX(10px) rotate(540deg);
		transform: rotate(-540deg) translateX(10px) rotate(540deg);
	}
}

@keyframes object3 {
	from {
		-webkit-transform: rotate(180deg) translateX(10px) rotate(-180deg);
		-moz-transform: rotate(180deg) translateX(10px) rotate(-180deg);
		-o-transform: rotate(180deg) translateX(10px) rotate(-180deg);
		transform: rotate(180deg) translateX(10px) rotate(-180deg);
	}

	to {
		-webkit-transform: rotate(-540deg) translateX(10px) rotate(540deg);
		-moz-transform: rotate(-540deg) translateX(10px) rotate(540deg);
		-o-transform: rotate(-540deg) translateX(10px) rotate(540deg);
		transform: rotate(-540deg) translateX(10px) rotate(540deg);
	}
}

.banknotes_desktop {
	display: block;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -520px;
	-webkit-animation: object2 20s infinite linear;
	-moz-animation: object2 20s infinite linear;
	-o-animation: object2 20s infinite linear;
	animation: object2 20s infinite linear;
}

.coins_desktop {
	display: block;
	position: absolute;
	top: 100px;
	left: 50%;
	margin-left: -365px;
	-webkit-animation: object2 15s infinite linear;
	-moz-animation: object2 15s infinite linear;
	-o-animation: object2 15s infinite linear;
	animation: object2 15s infinite linear;
}

.gifts_desktop {
	display: block;
	position: absolute;
	top: 20px;
	left: 50%;
	margin-left: -790px;
	-webkit-animation: object3 18s infinite linear;
	-moz-animation: object3 18s infinite linear;
	-o-animation: object3 18s infinite linear;
	animation: object3 18s infinite linear;
}

.banknotes_mobile,
.gifts_mobile {
	display: none;
}

/* *** */

.bonus_window {
	cursor: pointer;
	display: none;
	width: fit-content;
	height: 130px;
	border: 2px solid #0B306B;
	-webkit-border-radius: 41px;
	-moz-border-radius: 41px;
	border-radius: 41px;
	background: #071437;
	position: fixed;
	bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 100;
}

.bonus_window .bonus_icon {
	display: block;
	position: absolute;
	top: -40px;
	left: -20px;
}

.bonus_window .bonus_text {
	float: left;
	margin: 27px 30px 0 194px;
	font-style: italic;
	color: #fff;
	text-transform: uppercase;
}

.bonus_window .subtitle {
	margin: 0 0 5px;
	font-size: 22px;
}

.bonus_window .title {
	font-weight: 700;
	font-size: 24px;
	line-height: 1.2;
}

.bonus_window .title br {
	display: none;
}

.bonus_window .bonus_button {
	float: right;
	margin: 34px 40px 0 0;
	width: 220px;
	height: 60px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	background: #1f92ff;
	background: -webkit-gradient(linear, left top, left bottom, from(#1F92FF), to(#0056E2));
	background: -webkit-linear-gradient(top, #1F92FF 0%, #0056E2 100%);
	background: -moz-linear-gradient(top, #1F92FF 0%, #0056E2 100%);
	background: -o-linear-gradient(top, #1F92FF 0%, #0056E2 100%);
	background: linear-gradient(180deg, #1F92FF 0%, #0056E2 100%);
	-webkit-box-shadow: 0px 2px 22px -2px #005FE7;
	-moz-box-shadow: 0px 2px 22px -2px #005FE7;
	box-shadow: 0px 2px 22px -2px #005FE7;
	font-weight: 500;
	font-size: 20px;
	line-height: 60px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.bonus_window .bonus_button:hover {
	background: #2e98fd;
	background: -webkit-gradient(linear, left top, left bottom, from(#2e98fd), to(#1764e1));
	background: -webkit-linear-gradient(top, #2e98fd 0%, #1764e1 100%);
	background: -moz-linear-gradient(top, #2e98fd 0%, #1764e1 100%);
	background: -o-linear-gradient(top, #2e98fd 0%, #1764e1 100%);
	background: linear-gradient(180deg, #2e98fd 0%, #1764e1 100%);
}

.bonus_window .bonus_button:active {
	-webkit-transform: scale(0.99);
	-moz-transform: scale(0.99);
	-ms-transform: scale(0.99);
	-o-transform: scale(0.99);
	transform: scale(0.99);
}

/* *** */

.arcticmodal-container {
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
}

@media screen and (max-width: 1199px) {

	.banknotes_desktop {
		margin-left: -620px;
	}

	.coins_desktop {
		width: 1000px;
		margin-left: -250px;
	}

	.gifts_desktop {
		margin-left: -600px;
	}

}

@media screen and (max-width: 999px) {

	.main_title {
		font-size: 50px;
	}

	.photo_block .photo {
		top: -20px;
	}

	.photo_block .photo1 {
		width: 305px;
		height: auto;
	}

	.photo_block .photo2 {
		width: 345px;
		height: auto;
	}

	.spin_count_wrapper {
		font-size: 18px;
	}

	.spin_count_block {
		padding: 10px 20px;
		-webkit-border-radius: 21px;
		-moz-border-radius: 21px;
		border-radius: 21px;
	}

	.wheel_inner {
		width: 498px;
		height: 498px;
	}

	.wheel_inner:before {
		width: 509px;
		height: 494px;
		top: 2px;
		left: -5px;
	}

	.wheel_block {
		width: 439px;
		height: 439px;
		bottom: 28px;
		left: 30px;
	}

	.wheel_inner .marker {
		width: 36px;
		margin-left: -18px;
	}

	.wheel_inner .spin_button {
		width: 140px;
		height: 140px;
		font-size: 22px;
		line-height: 140px;
		margin-top: -70px;
		margin-left: -70px;
	}

	.banknotes_desktop {
		width: 900px;
		top: 20px;
		margin-left: -400px;
	}

	.coins_desktop {
		width: 900px;
		margin-left: -200px;
	}

	.gifts_desktop {
		width: 1000px;
		margin-left: -500px;
	}

}

@media screen and (max-width: 899px) {

	.bonus_window {
		cursor: pointer;
		width: 100%;
		height: auto;
		border: none;
		border-top: 2px solid #0B306B;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		bottom: 0;
		left: 0;
		margin-left: 0;
		text-align: center;
		transform: none;
	}

	.bonus_window .bonus_icon {
		display: inline-block;
		vertical-align: middle;
		position: static;
		width: 180px;
		margin: -10px -20px -10px -30px;
	}

	.bonus_window .bonus_text {
		display: inline-block;
		vertical-align: middle;
		float: none;
		margin: 0 30px 0 0;
		text-align: left;
	}

	.bonus_window .subtitle {
		margin: 0;
	}

	.bonus_window .bonus_button {
		display: inline-block;
		vertical-align: middle;
		float: none;
		margin: 0;
		width: 200px;
	}

}

@media screen and (max-width: 767px) {

	.main_wrapper {
		display: block;
		background-image: url("../../shared/img/bg_mobile.jpg");
	}

	.logo {
		width: 136px;
		top: 25px;
		margin-left: -68px;
	}

	.content_wrapper {
		padding: 70px 20px 250px;
		max-width: 360px;
	}

	.main_title {
		margin: 0 auto 25px;
		font-size: 28px;
		letter-spacing: normal;
	}

	.photo_block {
		position: static;
	}

	.photo_block .photo {
		top: auto;
	}

	.photo_block .photo1 {
		width: 300px;
		bottom: -330px;
		left: 50%;
		margin-left: -160px;
		z-index: 50;
	}

	.photo_block .photo2 {
		display: none;
	}

	.spin_count_wrapper {
		margin: 0 0 30px;
		font-size: 14px;
		line-height: 22px;
	}

	.spin_count_block {
		-webkit-border-radius: 18px;
		-moz-border-radius: 18px;
		border-radius: 18px;
	}

	.wheel_inner {
		margin-left: -30px;
		width: 380px;
		height: 380px;
	}

	.wheel_inner:before {
		width: 388px;
		height: 377px;
		top: 1px;
		left: -4px;
	}

	.wheel_block {
		width: 336px;
		height: 336px;
		bottom: 20px;
		left: 22px;
	}

	.wheel_inner .marker {
		width: 30px;
		margin-left: -15px;
	}

	.wheel_inner .spin_button {
		width: 108px;
		height: 108px;
		font-size: 16px;
		line-height: 108px;
		margin-top: -54px;
		margin-left: -54px;
	}

	.banknotes_desktop,
	.coins_desktop,
	.gifts_desktop {
		display: none;
	}

	.banknotes_mobile {
		display: block;
		position: absolute;
		top: 125px;
		left: 50%;
		margin-left: -210px;
		z-index: 51;
		-webkit-animation: object2 10s infinite linear;
		-moz-animation: object2 10s infinite linear;
		-o-animation: object2 10s infinite linear;
		animation: object2 10s infinite linear;
	}

	.gifts_mobile {
		display: block;
		position: absolute;
		top: 570px;
		left: 50%;
		margin-left: -240px;
		z-index: 51;
		-webkit-animation: object3 8s infinite linear;
		-moz-animation: object3 8s infinite linear;
		-o-animation: object3 8s infinite linear;
		animation: object3 8s infinite linear;
	}

	.bonus_window .bonus_icon {
		width: 160px;
		margin: -10px -20px -10px -35px;
	}

	.bonus_window .bonus_text {
		margin: 0 20px 0 0;
	}

	.bonus_window .subtitle {
		display: none;
	}

	.bonus_window .title {
		font-size: 20px;
	}

	.bonus_window .title br {
		display: block;
	}

	.bonus_window .bonus_button {
		width: 120px;
		height: 50px;
		-webkit-border-radius: 25px;
		-moz-border-radius: 25px;
		border-radius: 25px;
		font-size: 18px;
		line-height: 50px;
	}

}

@media screen and (max-width: 479px) {

	.bonus_window .bonus_icon {
		width: 140px;
		margin: 0 -20px 2px -35px;
	}

	.bonus_window .title {
		font-size: 14px;
	}

	.bonus_window .title span {
		display: block;
		font-weight: 900;
		font-size: 36px;
		line-height: 1;
	}

	.bonus_window .bonus_button {
		width: 100px;
		height: 40px;
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		border-radius: 20px;
		font-size: 16px;
		line-height: 40px;
	}

}

@media screen and (max-width: 359px) {

	.content_wrapper {
		max-width: 320px;
	}

	.main_title {
		font-size: 24px;
	}

	.wheel_inner {
		margin-left: -50px;
	}

	.bonus_window .bonus_icon {
		margin: 0 -25px 2px -35px;
	}

	.bonus_window .bonus_text {
		margin: 0 15px 0 0;
	}

}