html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.select2-container {
  box-sizing: border-box;
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  position: relative;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  height: 28px;
  -webkit-user-select: none;
  user-select: none;
  display: block;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 20px;
  display: block;
  overflow: hidden;
}

.select2-container .select2-selection--single .select2-selection__clear {
  background-color: #0000;
  border: none;
  font-size: 1em;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-left: 20px;
  padding-right: 8px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  min-height: 32px;
  -webkit-user-select: none;
  user-select: none;
  display: block;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 0;
  list-style: none;
  display: inline;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: #0000;
  border: none;
  font-size: 1em;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  word-break: keep-all;
  border: none;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  font-family: sans-serif;
  font-size: 100%;
  overflow: hidden;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  box-sizing: border-box;
  width: 100%;
  z-index: 1051;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: block;
  position: absolute;
  left: -100000px;
}

.select2-results {
  display: block;
}

.select2-results__options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.select2-results__option {
  -webkit-user-select: none;
  user-select: none;
  padding: 6px;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  padding: 4px;
  display: block;
}

.select2-search--dropdown .select2-search__field {
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  filter: alpha(opacity= 0);
  background-color: #fff;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.select2-hidden-accessible {
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  width: 1px !important;
  white-space: nowrap !important;
  border: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  height: 26px;
  margin-right: 20px;
  padding-right: 0;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  width: 20px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  height: 0;
  width: 0;
  border: 4px solid #0000;
  border-top: 5px solid #888;
  border-bottom-width: 0;
  margin-top: -2px;
  margin-left: -4px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  cursor: default;
  background-color: #eee;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 4px 5px;
  border-color: #0000 #0000 #888;
}

.select2-container--default .select2-selection--multiple {
  cursor: text;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  height: 20px;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
  font-weight: bold;
  position: absolute;
  right: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  box-sizing: border-box;
  max-width: 100%;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0 0 0 20px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 4px;
  font-size: 1em;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  color: #333;
  background-color: #f1f1f1;
  outline: none;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-radius: 0 4px 4px 0;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #000;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  cursor: default;
  background-color: #eee;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  box-shadow: none;
  -webkit-appearance: textfield;
  background: none;
  border: none;
  outline: 0;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--group {
  padding: 0;
}

.select2-container--default .select2-results__option--disabled {
  color: #999;
}

.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  color: #fff;
  background-color: #5897fb;
}

.select2-container--default .select2-results__group {
  cursor: default;
  padding: 6px;
  display: block;
}

.select2-container--classic .select2-selection--single {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFFFFFFF", endColorstr= "#FFEEEEEE", GradientType= 0);
  background-color: #f7f7f7;
  background-image: linear-gradient(#fff 50%, #eee 100%);
  background-repeat: repeat-x;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  height: 26px;
  margin-right: 20px;
  font-weight: bold;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  height: 26px;
  width: 20px;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFCCCCCC", GradientType= 0);
  background-color: #ddd;
  background-image: linear-gradient(#eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  height: 0;
  width: 0;
  border: 4px solid #0000;
  border-top: 5px solid #888;
  border-bottom-width: 0;
  margin-top: -2px;
  margin-left: -4px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 4px 0 0 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: none;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 4px 5px;
  border-color: #0000 #0000 #888;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFFFFFFF", endColorstr= "#FFEEEEEE", GradientType= 0);
  background-image: linear-gradient(#fff 0%, #eee 50%);
  background-repeat: repeat-x;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFFFFFFF", GradientType= 0);
  background-image: linear-gradient(#eee 50%, #fff 100%);
  background-repeat: repeat-x;
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--classic .select2-selection--multiple {
  cursor: text;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  display: inline-block;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 4px;
  font-size: 1em;
  font-weight: bold;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-radius: 0 4px 4px 0;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  box-shadow: none;
  outline: 0;
}

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid #0000;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option--group {
  padding: 0;
}

.select2-container--classic .select2-results__option--disabled {
  color: gray;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  color: #fff;
  background-color: #3875d7;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  padding: 6px;
  display: block;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.arcticmodal-overlay, .arcticmodal-container {
  z-index: 1000;
  position: fixed;
  inset: 0;
}

.arcticmodal-container {
  border-collapse: collapse;
  border: 0;
  margin: 0;
  padding: 0;
  overflow: auto;
}

:first-child + html .arcticmodal-container {
  height: 100%;
}

.arcticmodal-container_i {
  height: 100%;
  margin: 0 auto;
}

.arcticmodal-container_i2 {
  vertical-align: middle;
  border: 0;
  margin: 0;
  padding: 24px;
}

.arcticmodal-error {
  color: #fff;
  background: #000;
  border-radius: 10px;
  padding: 20px;
}

.arcticmodal-loading {
  width: 80px;
  height: 80px;
  background: #000 50% no-repeat;
  border-radius: 10px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #fa5a00 #0f2955;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #0f2955;
}

::-webkit-scrollbar-thumb {
  background-color: #fa5a00;
}

html {
  height: 100%;
}

html * {
  box-sizing: border-box;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  height: 100%;
  min-width: 320px;
  cursor: default;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  position: relative;
}

.main_wrapper {
  min-height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background: #051130 url("bg_desktop.5dc574dc.jpg") center / cover no-repeat;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.logo {
  margin-left: -105px;
  display: block;
  position: absolute;
  top: 30px;
  left: 50%;
}

.content_wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 90px 20px 30px;
  position: relative;
}

.main_title {
  width: 100%;
  max-width: 700px;
  letter-spacing: 1.2px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto 40px;
  font-size: 60px;
  font-style: italic;
  font-weight: 900;
  line-height: 1.1;
}

.photo_block {
  position: relative;
}

.photo_block .photo {
  position: absolute;
  top: -40px;
}

.photo_block .photo1 {
  left: -60px;
}

.photo_block .photo2 {
  right: -85px;
}

.spin_count_wrapper {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 40px;
  font-size: 20px;
  line-height: 26px;
}

.spin_count_block {
  background: #071437;
  border-radius: 23px;
  padding: 11px 30px;
}

.spin_count_block .count {
  font-weight: 700;
}

.wheel_inner {
  width: 597px;
  height: 597px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background: #081339 url("wheel_bg.3a85c153.png") center / cover no-repeat;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 0 30px #ff790b26;
}

@keyframes glow {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.wheel_inner:before {
  content: "";
  width: 615px;
  height: 595px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background: url("wheel_glows.e3944ae6.png") center / cover no-repeat;
  animation: .6s linear infinite glow;
  display: block;
  position: absolute;
  top: 1px;
  left: -9px;
}

.wheel_block {
  width: 527px;
  height: 527px;
  position: absolute;
  bottom: 33px;
  left: 35px;
}

@keyframes rotate1 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(2160deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(2160deg);
  }

  100% {
    transform: rotate(4248deg);
  }
}

.wheel_wrapper .rotate1 {
  animation: 5s cubic-bezier(0, .8, .26, .99) forwards rotate1;
}

.wheel_wrapper .rotate2 {
  animation: 5s cubic-bezier(0, .8, .26, .99) forwards rotate2;
}

.wheel_block .wheel {
  width: 100%;
  height: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.wheel_block .wheel_active {
  opacity: 0;
}

.wheel_block .wheel_active.show {
  opacity: 1;
}

.wheel_inner .marker {
  width: 44px;
  height: auto;
  margin-left: -22px;
  display: block;
  position: absolute;
  top: -4px;
  left: 50%;
}

.wheel_inner .spin_button {
  width: 168px;
  height: 168px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  z-index: 99;
  background: #ff4700;
  border-radius: 50%;
  margin-top: -84px;
  margin-left: -84px;
  font-size: 26px;
  font-weight: 900;
  line-height: 168px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.wheel_inner .spin_button:hover {
  background: #fc5c1e;
}

.wheel_inner .spin_button:active {
  transform: scale(.99);
}

.wheel_inner .spin_button.disabled {
  color: #fff6;
  cursor: default;
  background: #fc5c1e;
}

.wheel_inner .spin_button.disabled:active {
  transform: none;
}

@keyframes object1 {
  from {
    transform: rotate(180deg)translateX(20px)rotate(-180deg);
  }

  to {
    transform: rotate(540deg)translateX(20px)rotate(-540deg);
  }
}

@keyframes object2 {
  from {
    transform: rotate(180deg)translateX(15px)rotate(-180deg);
  }

  to {
    transform: rotate(540deg)translateX(15px)rotate(-540deg);
  }
}

@keyframes object3 {
  from {
    transform: rotate(180deg)translateX(10px)rotate(-180deg);
  }

  to {
    transform: rotate(-540deg)translateX(10px)rotate(540deg);
  }
}

.banknotes_desktop {
  margin-left: -520px;
  animation: 20s linear infinite object2;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.coins_desktop {
  margin-left: -365px;
  animation: 15s linear infinite object2;
  display: block;
  position: absolute;
  top: 100px;
  left: 50%;
}

.gifts_desktop {
  margin-left: -790px;
  animation: 18s linear infinite object3;
  display: block;
  position: absolute;
  top: 20px;
  left: 50%;
}

.banknotes_mobile, .gifts_mobile {
  display: none;
}

.bonus_window {
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  height: 130px;
  z-index: 100;
  background: #071437;
  border: 2px solid #0b306b;
  border-radius: 41px;
  display: none;
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.bonus_window .bonus_icon {
  display: block;
  position: absolute;
  top: -40px;
  left: -20px;
}

.bonus_window .bonus_text {
  float: left;
  color: #fff;
  text-transform: uppercase;
  margin: 27px 30px 0 194px;
  font-style: italic;
}

.bonus_window .subtitle {
  margin: 0 0 5px;
  font-size: 22px;
}

.bonus_window .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
}

.bonus_window .title br {
  display: none;
}

.bonus_window .bonus_button {
  float: right;
  width: 220px;
  height: 60px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: linear-gradient(#1f92ff 0%, #0056e2 100%);
  border-radius: 30px;
  margin: 34px 40px 0 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 60px;
  box-shadow: 0 2px 22px -2px #005fe7;
}

.bonus_window .bonus_button:hover {
  background: linear-gradient(#2e98fd 0%, #1764e1 100%);
}

.bonus_window .bonus_button:active {
  transform: scale(.99);
}

.arcticmodal-container {
  -webkit-backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

@media screen and (width <= 1199px) {
  .banknotes_desktop {
    margin-left: -620px;
  }

  .coins_desktop {
    width: 1000px;
    margin-left: -250px;
  }

  .gifts_desktop {
    margin-left: -600px;
  }
}

@media screen and (width <= 999px) {
  .main_title {
    font-size: 50px;
  }

  .photo_block .photo {
    top: -20px;
  }

  .photo_block .photo1 {
    width: 305px;
    height: auto;
  }

  .photo_block .photo2 {
    width: 345px;
    height: auto;
  }

  .spin_count_wrapper {
    font-size: 18px;
  }

  .spin_count_block {
    border-radius: 21px;
    padding: 10px 20px;
  }

  .wheel_inner {
    width: 498px;
    height: 498px;
  }

  .wheel_inner:before {
    width: 509px;
    height: 494px;
    top: 2px;
    left: -5px;
  }

  .wheel_block {
    width: 439px;
    height: 439px;
    bottom: 28px;
    left: 30px;
  }

  .wheel_inner .marker {
    width: 36px;
    margin-left: -18px;
  }

  .wheel_inner .spin_button {
    width: 140px;
    height: 140px;
    margin-top: -70px;
    margin-left: -70px;
    font-size: 22px;
    line-height: 140px;
  }

  .banknotes_desktop {
    width: 900px;
    margin-left: -400px;
    top: 20px;
  }

  .coins_desktop {
    width: 900px;
    margin-left: -200px;
  }

  .gifts_desktop {
    width: 1000px;
    margin-left: -500px;
  }
}

@media screen and (width <= 899px) {
  .bonus_window {
    cursor: pointer;
    width: 100%;
    height: auto;
    text-align: center;
    border: none;
    border-top: 2px solid #0b306b;
    border-radius: 0;
    margin-left: 0;
    bottom: 0;
    left: 0;
    transform: none;
  }

  .bonus_window .bonus_icon {
    vertical-align: middle;
    width: 180px;
    margin: -10px -20px -10px -30px;
    display: inline-block;
    position: static;
  }

  .bonus_window .bonus_text {
    vertical-align: middle;
    float: none;
    text-align: left;
    margin: 0 30px 0 0;
    display: inline-block;
  }

  .bonus_window .subtitle {
    margin: 0;
  }

  .bonus_window .bonus_button {
    vertical-align: middle;
    float: none;
    width: 200px;
    margin: 0;
    display: inline-block;
  }
}

@media screen and (width <= 767px) {
  .main_wrapper {
    background-image: url("bg_mobile.cfb19f84.jpg");
    display: block;
  }

  .logo {
    width: 136px;
    margin-left: -68px;
    top: 25px;
  }

  .content_wrapper {
    max-width: 360px;
    padding: 70px 20px 250px;
  }

  .main_title {
    letter-spacing: normal;
    margin: 0 auto 25px;
    font-size: 28px;
  }

  .photo_block {
    position: static;
  }

  .photo_block .photo {
    top: auto;
  }

  .photo_block .photo1 {
    width: 300px;
    z-index: 50;
    margin-left: -160px;
    bottom: -330px;
    left: 50%;
  }

  .photo_block .photo2 {
    display: none;
  }

  .spin_count_wrapper {
    margin: 0 0 30px;
    font-size: 14px;
    line-height: 22px;
  }

  .spin_count_block {
    border-radius: 18px;
  }

  .wheel_inner {
    width: 380px;
    height: 380px;
    margin-left: -30px;
  }

  .wheel_inner:before {
    width: 388px;
    height: 377px;
    top: 1px;
    left: -4px;
  }

  .wheel_block {
    width: 336px;
    height: 336px;
    bottom: 20px;
    left: 22px;
  }

  .wheel_inner .marker {
    width: 30px;
    margin-left: -15px;
  }

  .wheel_inner .spin_button {
    width: 108px;
    height: 108px;
    margin-top: -54px;
    margin-left: -54px;
    font-size: 16px;
    line-height: 108px;
  }

  .banknotes_desktop, .coins_desktop, .gifts_desktop {
    display: none;
  }

  .banknotes_mobile {
    z-index: 51;
    margin-left: -210px;
    animation: 10s linear infinite object2;
    display: block;
    position: absolute;
    top: 125px;
    left: 50%;
  }

  .gifts_mobile {
    z-index: 51;
    margin-left: -240px;
    animation: 8s linear infinite object3;
    display: block;
    position: absolute;
    top: 570px;
    left: 50%;
  }

  .bonus_window .bonus_icon {
    width: 160px;
    margin: -10px -20px -10px -35px;
  }

  .bonus_window .bonus_text {
    margin: 0 20px 0 0;
  }

  .bonus_window .subtitle {
    display: none;
  }

  .bonus_window .title {
    font-size: 20px;
  }

  .bonus_window .title br {
    display: block;
  }

  .bonus_window .bonus_button {
    width: 120px;
    height: 50px;
    border-radius: 25px;
    font-size: 18px;
    line-height: 50px;
  }
}

@media screen and (width <= 479px) {
  .bonus_window .bonus_icon {
    width: 140px;
    margin: 0 -20px 2px -35px;
  }

  .bonus_window .title {
    font-size: 14px;
  }

  .bonus_window .title span {
    font-size: 36px;
    font-weight: 900;
    line-height: 1;
    display: block;
  }

  .bonus_window .bonus_button {
    width: 100px;
    height: 40px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 40px;
  }
}

@media screen and (width <= 359px) {
  .content_wrapper {
    max-width: 320px;
  }

  .main_title {
    font-size: 24px;
  }

  .wheel_inner {
    margin-left: -50px;
  }

  .bonus_window .bonus_icon {
    margin: 0 -25px 2px -35px;
  }

  .bonus_window .bonus_text {
    margin: 0 15px 0 0;
  }
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"], input.phone-input {
  -moz-appearance: textfield;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  height: 28px;
  -webkit-user-select: none;
  user-select: none;
  display: block;
}

.select2-container--default .select2-selection--single {
  height: 60px;
  background-color: #fff;
  border: 0 solid #aaa;
  border-radius: 30px;
  outline: none;
}

.phone-prefix__select + .select2-container, .phone-prefix__select + .select2-container .select2-selection, .phone-prefix__select + .select2-container .selection {
  height: 100%;
  outline: none;
}

.phone-prefix__select + .select2-container {
  flex: none;
}

.phone-prefix__select + .select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 0;
}

.phone-prefix__select + .select2-container--default {
  height: 30px !important;
}

.phone-prefix__wrap {
  background: #fff;
  border-radius: 30px;
  align-items: center;
  padding-left: 10px;
  padding-right: 20px;
  display: flex;
  box-shadow: 0 2px 2px #0000003d;
}

.phone-prefix__code {
  color: #000;
  text-align: left;
  margin-right: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 31px;
  display: inline-block;
}

.phone-prefix__code-select {
  align-items: center;
  display: flex;
}

.phone-prefix__flag {
  width: 30px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.list-select {
  background-color: #fff;
  border: 1px solid #000;
  font-size: 20px;
}

.list-select + .select2 {
  margin-bottom: 8px;
}

.list-select + .select2 .select2-selection--single {
  height: 60px;
  text-align: left;
  align-items: center;
  display: flex;
}

.list-select + .select2 .select2-selection__rendered {
  padding-left: 30px;
  font-size: 20px;
}

.list-select + .select2 .select2-selection__arrow {
  height: 100%;
  padding-right: 20px;
}

.select2 * {
  box-sizing: content-box;
}

.select2-results__option[aria-selected] {
  color: #000 !important;
}

.register {
  z-index: 100;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  transition: all .2s;
  display: flex;
  position: relative;
}

.register__bonus {
  max-width: 350px;
  max-height: 43px;
  z-index: 1;
  box-sizing: border-box;
  border-radius: 30px;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto 24px;
  padding: 10px 20px;
  display: flex;
}

.register__bonus-text {
  text-align: left;
  text-transform: uppercase;
  margin-left: 20px;
  margin-right: auto;
  font-size: 17px;
  font-style: italic;
  font-weight: 900;
  line-height: 20px;
}

.register__img-wrap {
  z-index: 1;
  position: relative;
}

.register__bonus-img {
  max-width: 45px;
  margin-top: -10px;
}

.register__bonus--deposit {
  background: linear-gradient(#ffc887 0%, #d38044 100%);
  margin-top: 20px;
  box-shadow: 0 0 19px #ff9e4996;
}

.register__bonus--casino {
  background: linear-gradient(#ff76f1 0%, #731dff 100%);
  box-shadow: 0 0 19px #db49ff96;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out;
  -webkit-box-shadow: inset 0 0 0 1000px #052547;
  -webkit-text-fill-color: white !important;
}

.register__wrapper {
  width: -moz-max-content;
  max-width: 516px;
  width: 100%;
  background-color: #071437;
  border: 2px solid #0b306b;
  border-radius: 42px;
  position: relative;
  box-shadow: 0 3px 20px -10px #000;
}

.register__desc {
  box-sizing: border-box;
  text-align: center;
  padding: 30px 40px;
  font-family: Roboto;
}

.register__desc__title {
  text-transform: uppercase;
  font-size: 42px;
  font-weight: 900;
  line-height: 1;
}

.register__desc__description {
  text-transform: uppercase;
  margin: 15px 0;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.3;
}

.register__desc__description span {
  color: #fb4d00;
}

.register__desc__text {
  font-size: 16px;
  line-height: 1.5;
}

.register__desc-title {
  color: #fff;
  text-transform: uppercase;
  z-index: 5;
  padding: 12px;
  font-size: 26px;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
  line-height: 1;
  position: relative;
}

.register__desc-price {
  color: #f8c927;
  text-transform: uppercase;
  z-index: 5;
  text-shadow: 0 0 17px #f8c927b5;
  font-size: 34px;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
  line-height: 1;
  position: relative;
}

.register__desc-bg {
  letter-spacing: normal;
  text-align: left;
  color: #726950;
  text-transform: uppercase;
  z-index: 1;
  font-family: Thin, sans-serif;
  font-size: 56px;
  font-style: italic;
  font-weight: 100;
  font-stretch: normal;
  line-height: 1;
  position: absolute;
  top: 27px;
  left: 50px;
}

.register__desc-text {
  letter-spacing: normal;
  text-align: center;
  color: #56b9ff;
  max-width: 327px;
  text-shadow: 0 0 15px #56b9ff;
  margin: 5px auto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 130%;
}

.selection {
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
}

.tabs__wrapper {
  height: 80px;
  background-color: #071437;
  display: flex;
  width: 100% !important;
}

.tab {
  cursor: pointer;
  color: #fff;
  justify-content: center;
  align-items: center;
  padding: 17px 0;
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: normal;
  line-height: 1.2;
  display: flex;
  width: 100% !important;
}

.tab:hover {
  color: #f4b909;
  background-image: linear-gradient(to top, #012347, #052547);
  transition: all .5s;
}

.tab__text {
  padding-left: 13px;
}

.active {
  color: #fff;
  background-image: linear-gradient(to top, #012347, #052547);
}

.tab.active {
  z-index: 999;
  position: relative;
}

.img-phone {
  width: 15px;
  height: 16px;
}

.img-mail {
  width: 15px;
  height: 12px;
}

.shape {
  background: linear-gradient(0deg, #02255a 26.27%, #0d3271 115.69%);
  border-radius: 32px;
  padding: 37px 37px 27px;
  box-shadow: 0 15px 23px #00000040;
}

.form {
  position: relative;
}

.form__wrapper {
  flex-direction: column;
  display: flex;
}

.form__row {
  margin-top: 19px;
  position: relative;
}

.form__wrapper > .form__row {
  margin-top: 10px;
  position: relative;
}

.form__row:first-child {
  margin-top: 0;
}

.phone-prefix__wrap--modified {
  background: initial;
  box-shadow: none;
  align-items: center;
  padding: 0;
  display: flex;
  position: relative;
}

.phone-prefix__flag {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  left: 18px;
}

select:not(:-internal-list-box) {
  overflow: visible !important;
}

.phone-prefix__select {
  width: 38px;
  height: 40px;
  -webkit-appearance: none;
  background: url("select.ea666f70.png") 100% no-repeat;
  border: none;
  font-size: 26px;
  font-weight: 600;
  position: absolute;
  left: 30px;
}

.phone-prefix__code {
  color: #fff;
  text-align: left;
  height: 100%;
  align-items: center;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.25;
  display: flex;
  position: absolute;
  top: 0;
  left: 50px;
}

.phone-input {
  padding: 10px 3px 9px 160px;
}

.email__img {
  width: 28px;
  height: 22px;
  display: none;
  position: absolute;
  top: 48px;
  left: 30px;
}

.email-input {
  letter-spacing: .8px;
  padding: 10px 42px 9px 70px;
}

.password-input {
  letter-spacing: .8px;
  margin-left: 0;
  padding: 10px 42px 9px 70px;
}

.password__img {
  width: 30px;
  height: 28px;
  display: none;
  position: absolute;
  top: 40px;
  left: 30px;
}

.password-control {
  width: 30px;
  height: 70px;
  background: url("no-view.aaec82a7.svg") center no-repeat;
  display: flex;
  position: absolute;
  top: 0;
  right: 23px;
}

.password-control.view {
  background: url("view.8a969ecd.svg") center no-repeat;
}

.inp-form {
  text-align: left;
  width: 100%;
  height: 70px;
  max-width: -moz-available;
  max-width: -webkit-fill-available;
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  border: 2px solid #164b9a;
  border-radius: 100px;
  outline: #0000;
  font-family: inherit;
  font-size: 15px;
  font-weight: 600;
  background-color: #0000 !important;
}

.inp-form::placeholder {
  color: #fff;
  opacity: .5;
  font-weight: 10;
}

.currency__inp {
  background-color: #012347;
  padding: 10px 10px 9px 74px;
  position: relative;
}

.currency__inp--email {
  margin-left: 0;
}

.currency__img {
  width: 30px;
  height: 26px;
  display: none;
  position: absolute;
  left: 30px;
}

.list-select {
  -webkit-appearance: none;
  height: 45px;
  width: 400px;
  background: url("select.ea666f70.png") 100% no-repeat;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  top: 23px;
  left: 70px;
  width: 90% !important;
  height: 100% !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.formError {
  color: #d63333;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
  padding-left: 5%;
  font-size: 12px;
  display: flex;
}

.botom-pop {
  justify-content: center;
  margin-top: 42px;
  display: flex;
}

.form-btn {
  color: #fff;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  max-height: 70px;
  width: 100%;
  height: 100px;
  z-index: 1000;
  background-image: linear-gradient(to top, #fa4d00, #fa4d00);
  border: 4px solid #0000;
  border-radius: 100px;
  outline: #0000;
  padding: 4px;
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 500;
  box-shadow: 0 11px 27px #fa41006e;
}

.form-btn:hover {
  transition: all .3s;
  box-shadow: 0 11px 27px #fa4100a1;
}

.select2-container {
  position: absolute;
}

.select2-container--default {
  position: absolute;
  top: 19px;
  left: 0;
}

.phone-prefix__select + .select2-container {
  left: 20px;
  height: 100% !important;
  top: 0 !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.select2-container--default .select2-selection--single {
  background: none;
  align-items: center;
  display: flex;
}

img.undefined, .phone-prefix__flag {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: inherit;
  border-style: inherit;
  border-width: inherit;
  height: inherit;
  margin-left: inherit;
  margin-top: inherit;
  left: 0;
  top: inherit;
  width: inherit;
  -webkit-appearance: none;
  background: url("select.ea666f70.png") 100% no-repeat;
}

.phone-prefix__select + .select2-container--default .select2-selection--single .select2-selection__arrow {
  left: 110px;
  height: 100% !important;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: inherit;
  border-width: inherit;
  -webkit-appearance: none;
  background: url("select.ea666f70.png") 100% / 12px no-repeat;
  left: 7px;
  transform: rotate(-180deg);
}

.list-select + .select2 {
  height: 100%;
  align-items: center;
  display: flex;
  top: 0;
  left: auto;
  right: 0;
  width: 100% !important;
}

.list-select + .select2 .select2-selection__rendered {
  color: #fff;
  padding-left: 30px;
  font-size: 15px;
  font-weight: 300;
  position: relative;
}

.list-select + .select2 .select2-selection--single {
  width: 100%;
  height: 100% !important;
}

.phone-prefix__select + .select2-container--default {
  position: absolute;
  top: 44px;
}

.form__checkbox {
  font-size: 15px;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .register__wrapper {
    width: 100%;
  }

  .inp-form {
    width: auto;
    min-width: 310px;
  }
}

.overlay-bg {
  z-index: -30;
  width: 100%;
  height: 100%;
  position: absolute;
}

.register__desc-text {
  color: #fff !important;
  text-shadow: none !important;
}

.white-text {
  color: #fff !important;
  text-shadow: 0 0 5px #fff !important;
}

.botom-pop {
  padding-bottom: 8px;
}

.list-select, .currency__img {
  top: 50%;
  transform: translate(0, -50%);
}

.active {
  background-color: #0a2f6c !important;
  background-image: none !important;
}

.tab:first-child {
  border-radius: 100px 100px 130px 0;
  position: relative;
}

.tab:nth-child(2) {
  border-radius: 100px 100px 0 130px;
  position: relative;
}

.tab:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: -1;
  background-color: #071437;
  position: absolute;
}

.tab:first-child:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: -2;
  position: absolute;
  bottom: -28px;
  right: -38px;
  background-color: #0a2f6c !important;
}

.tab:nth-child(2):before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: -1;
  background-color: #071437;
  position: absolute;
}

.tab:nth-child(2):after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: -2;
  position: absolute;
  bottom: -28px;
  left: -38px;
  background-color: #0a2f6c !important;
}

.tab {
  transition: unset !important;
}

.tab.active:before {
  content: "";
  width: 100%;
  height: 100%;
  bottom: -30px;
  left: 0;
  right: auto;
  background-color: #0a2f6c !important;
}

.tab.active:after {
  content: "";
  width: 100%;
  height: 100%;
  bottom: -30px;
  left: auto;
  right: 0;
  background-color: #0a2f6c !important;
}

.tab {
  z-index: 100;
}

.shape {
  background-color: #164b9a;
}

.tab:hover {
  background-image: none;
  background-color: #0a2f6c !important;
}

.tab:hover .tab__text {
  color: #fff;
}

.register__img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.register__img-pic {
  position: absolute;
}

.register__img-pic--one {
  width: 40%;
  top: 150px;
  left: 540px;
}

.form__checkbox {
  padding: 10px;
}

.form__checkbox-item {
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.form__checkbox-item + .form__label {
  -webkit-user-select: none;
  user-select: none;
  align-items: flex-start;
  display: inline-flex;
}

.form__checkbox-item + .form__label:before {
  content: "";
  width: 1em;
  height: 1em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 65% 65%;
  border: 2px solid #164b9a;
  border-radius: .25em;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: .5em;
  display: inline-block;
}

.form__checkbox-item:checked + .form__label:before {
  background-color: #0b76ef;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACASURBVHgBfc8xDkRAFIBhNptsttxmC4nKbdQKtUIhDsBp3MEVdA7hAAoRdMT4J55ExHjJFwm/x9iWYZRSLy4FftZDFGDBaAr+aNU+2V3wRilBja++6SOGLVGIFQO8481Bvp3DQSdb0vP6BLOEjQQVPtf/SCXU08M1nUhvnBBdn221aZeppnF34QAAAABJRU5ErkJggg==");
  border-color: #0b76ef;
}

.form__checkbox-item:required {
  display: none;
}

.form__checkbox-item:required:invalid + .form__label:before {
  border: 2px solid #ff2768;
}

@media screen and (width <= 1920px), (width >= 1920px) {
  .register__wrapper {
    flex-direction: column;
    flex: 0 0 534px;
    display: flex;
  }

  .form__wrapper {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  .password-input {
    margin-left: 0;
  }

  .register__desc-title {
    font-size: 27px;
  }

  .register__desc-price {
    font-size: 34px;
  }

  .register__desc-bg {
    font-size: 42px;
  }

  .register__desc-text {
    font-size: 15px;
  }

  .tabs__wrapper {
    width: 420px;
    height: 60px;
  }

  .tab {
    font-size: 17px;
  }

  .shape {
    padding: 20px;
  }

  .form__wrapper > .form__row {
    margin-top: 10px;
  }

  .inp-form {
    height: 70px;
    font-size: 15px;
    width: 100% !important;
  }

  .img-mail {
    width: 15px;
    height: 12px;
  }

  .img-phone {
    width: 15px;
    height: 16px;
  }

  .email__img {
    width: 20px;
    height: 16px;
    top: 33px;
    left: 20px;
  }

  .password__img {
    width: 20px;
    height: 20px;
    top: 30px;
    left: 20px;
  }

  .password-control {
    width: 20px;
  }

  .currency__img {
    width: 20px;
    height: 18px;
    left: 20px;
  }

  .list-select {
    width: 306px;
    left: 50px;
  }

  .email-input, .password-input {
    padding: 10px 30px;
  }

  .botom-pop {
    margin-top: 15px;
  }

  .form-btn {
    width: 100%;
    height: 76px;
    font-size: 28px;
  }

  .list-select + .select2 .select2-selection--single {
    height: 40px;
  }

  .list-select + .select2 .select2-selection__rendered {
    padding-left: 30px;
    font-size: 15px;
    font-weight: 300;
  }

  .phone-prefix__code {
    height: 100%;
    align-items: center;
    font-size: 15px;
    display: flex;
    top: 0 !important;
  }

  .phone-prefix__select + .select2-container--default {
    top: 24px;
  }

  .phone-prefix__select + .select2-container {
    left: 13px;
  }

  .phone-input {
    padding: 9px 3px 9px 125px;
  }

  .select2-container .select2-selection--single .select2-selection__rendered {
    width: 24px;
    height: 24px;
  }

  .phone-prefix__select + .select2-container--default .select2-selection--single .select2-selection__arrow {
    left: 80px;
  }
}

@media screen and (width <= 959px) {
  .register__wrapper {
    min-width: 355px;
  }

  .form-btn {
    font-size: 24px;
  }

  .register__bonus-text {
    font-size: 13px;
  }
}

@media screen and (width <= 767px) {
  .bonus {
    flex-wrap: wrap;
  }

  .bonus__item {
    max-width: 370px;
    max-height: 70px;
    flex: 0 0 100%;
    margin: 0 auto;
  }

  .register__wrapper {
    max-width: 370px;
    min-width: unset;
    min-width: 340px;
    border-radius: 30px;
    flex-direction: column;
    display: flex;
  }

  .form__wrapper {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  .password-input {
    margin-left: 0;
  }

  .register__desc {
    padding: 25px 15px;
  }

  .register__desc__title {
    font-size: 30px;
  }

  .register__desc__description {
    font-size: 18px;
  }

  .register__desc__text {
    font-size: 14px;
  }

  .register__desc-title {
    font-size: 27px;
    display: block;
  }

  .register__desc-price {
    font-size: 40px;
  }

  .register__desc-bg {
    font-size: 26px;
  }

  .register__desc-text {
    font-size: 15px;
  }

  .tabs__wrapper {
    width: 280px;
    height: 40px;
  }

  .tab {
    font-size: 12px;
  }

  .tab:first-child:after, .tab:nth-child(2):after {
    bottom: -17px;
  }

  .shape {
    border-radius: 22px;
    padding: 13px 20px 15px;
  }

  .form__wrapper > .form__row {
    margin-top: 10px;
  }

  .inp-form {
    height: 60px;
    width: 240px;
    font-size: 12px;
  }

  .img-mail {
    width: 15px;
    height: 12px;
  }

  .img-phone {
    width: 15px;
    height: 16px;
  }

  .email__img {
    width: 16px;
    height: 13px;
    top: 20px;
    left: 20px;
  }

  .password__img {
    width: 16px;
    height: 16px;
    top: 17px;
    left: 20px;
  }

  .password-control {
    width: 16px;
    height: 60px;
    right: 23px;
  }

  .currency__img {
    width: 16px;
    height: 14px;
    left: 20px;
  }

  .list-select {
    width: 170px;
    left: 50px;
  }

  .email-input, .password-input {
    padding: 10px 30px;
  }

  .botom-pop {
    margin-top: 15px;
  }

  .form-btn {
    height: 60px;
    font-size: 20px;
  }

  .list-select + .select2 .select2-selection--single {
    height: 12px;
  }

  .list-select + .select2 .select2-selection__rendered {
    padding-left: 30px;
    font-size: 12px;
  }

  .phone-prefix__code {
    font-size: 12px;
    top: 20px;
    left: 45px;
  }

  .phone-prefix__select + .select2-container--default {
    top: 12px;
  }

  .phone-prefix__select + .select2-container {
    left: 13px;
  }

  .phone-input {
    padding: 10px 3px 9px 100px;
  }

  .select2-container .select2-selection--single .select2-selection__rendered {
    width: 20px;
    height: 20px;
  }

  .phone-prefix__select + .select2-container--default .select2-selection--single .select2-selection__arrow {
    left: 55px;
  }

  .tab.active:before, .tab.active:after {
    bottom: -22px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    background-size: 12px;
  }
}

@media screen and (width <= 568px) {
  .register__bonus-text {
    line-height: 110%;
  }

  .register__desc-price {
    font-size: 40px;
  }

  .form__checkbox {
    font-size: 12px;
  }

  .botom-pop {
    margin-top: 10px;
  }

  .register__desc-title {
    font-size: 21px;
  }

  .bonuses {
    margin-bottom: 15px;
  }
}

@media screen and (width <= 390px) {
  .register__desc-title {
    font-size: 18px;
  }

  .register__bonus-text {
    font-size: 11px;
  }

  .register__wrapper {
    max-width: 295px;
    min-width: 100%;
  }

  .phone-input {
    padding: 10px 3px 9px 97px;
  }

  .register__wrapper {
    max-width: 310px;
  }

  .register__desc-text {
    margin: 2px auto 15px;
  }
}

@media screen and (width: 320px) {
  .register__wrapper {
    max-width: 280px;
  }

  .register__desc__title {
    font-size: 28px;
  }

  .register__desc__description {
    font-size: 16px;
  }

  .register__desc__text {
    font-size: 14px;
  }
}

@media screen and (height <= 800px) {
  .tabs__wrapper {
    max-height: 54px;
  }

  .form__row {
    margin-top: 16px;
  }

  .botom-pop {
    margin-top: 12px;
    padding-bottom: 0;
  }
}

@media screen and (width >= 876px) and (height <= 850px) {
  .register__desc {
    padding: 15px 25px;
  }

  .register__wrapper {
    margin-bottom: 10px;
  }

  .register__bonus--deposit {
    margin-top: 14px;
    margin-bottom: 15px;
  }
}

.logo {
  width: 240px;
  height: auto;
  margin-left: -120px;
}

.bonus_window .bonus_text {
  width: 240px;
  margin: 35px 30px 0 194px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
}

.bonus_window .bonus_button {
  width: 180px;
}

@media screen and (width <= 999px) {
  .main_title {
    max-width: 600px;
  }
}

@media screen and (width <= 899px) {
  .bonus_window .bonus_text {
    margin: 0;
  }
}

@media screen and (width <= 767px) {
  .logo {
    width: 170px;
    margin-left: -85px;
  }

  .bonus_window .bonus_text {
    width: 200px;
    font-size: 20px;
  }

  .bonus_window .bonus_button {
    width: 120px;
    font-size: 16px;
  }
}

@media screen and (width <= 479px) {
  .bonus_window .bonus_text, .bonus_window .bonus_button {
    width: 100px;
    font-size: 14px;
  }
}

/*# sourceMappingURL=index.9871bcbd.css.map */
