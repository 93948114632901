@media screen and (max-width: 1920px), (min-width: 1920px) {
  .register__wrapper {
    display: flex;
    flex-direction: column;
    flex: 0 0 534px;
  }

  .form__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .password-input {
    margin-left: 0;
  }
  /* .register__desc {
    padding: 10px 20px;
  } */
  .register__desc-title {
    font-size: 27px;
  }
  .register__desc-price {
    font-size: 34px;
  }
  .register__desc-bg {
    font-size: 42px;
  }
  .register__desc-text {
    font-size: 15px;
  }
  .tabs__wrapper {
    width: 420px;
    height: 60px;
  }
  .tab {
    font-size: 17px;
  }
  .shape {
    padding: 20px;
  }
  .form__wrapper > .form__row {
    margin-top: 10px;
  }
  .inp-form {
    height: 70px;
    font-size: 15px;
    /* width: 378px; */
    width: 100% !important;
  }
  .img-mail {
    width: 15px;
    height: 12px;
  }
  .img-phone {
    width: 15px;
    height: 16px;
  }
  .email__img {
    left: 20px;
    top: 33px;
    width: 20px;
    height: 16px;
  }
  .password__img {
    left: 20px;
    top: 30px;
    width: 20px;
    height: 20px;
  }
  .password-control {
    width: 20px;
  }
  .currency__img {
    left: 20px;
    width: 20px;
    height: 18px;
  }
  .list-select {
    left: 50px;
    width: 306px;
  }
  .email-input {
    padding: 10px 30px;
  }
  .password-input {
    padding: 10px 30px;
  }
  .botom-pop {
    margin-top: 15px;
  }
  .form-btn {
    /* max-width: 378px; */
    width: 100%;
    height: 76px;
    font-size: 28px;
  }

  .list-select + .select2 .select2-selection--single {
    height: 40px;
  }
  .list-select + .select2 .select2-selection__rendered {
    font-size: 15px;
    font-weight: 300;
    padding-left: 30px;
  }

  .phone-prefix__code {
    font-size: 15px;
    height: 100%;
    top: 0 !important;
    display: flex;
    align-items: center;
  }
  .phone-prefix__select + .select2-container--default {
    top: 24px;
  }
  .phone-prefix__select + .select2-container {
    left: 13px;
  }
  .phone-input {
    padding: 9px 3px 9px 125px;
  }
  .select2-container .select2-selection--single .select2-selection__rendered {
    width: 24px;
    height: 24px;
  }
  .phone-prefix__select
    + .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    left: 80px;
  }
}

@media screen and (max-width:959px) {
  .register__wrapper {
    min-width:355px;
  }
  .form-btn {
    font-size: 24px;
  }

  .register__bonus-text {
    font-size: 13px;
}
}

@media screen and (max-width: 767px) {
  .bonus {
    flex-wrap: wrap;
  }
  .bonus__item {
    max-width: 370px;
    margin: 0 auto;
    flex: 0 0 100%;
    max-height: 70px;
  }

  .bonus__img--left {}

  .bonus__img--right {}
  .register__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 370px;
    border-radius: 30px;
    min-width: unset;
    min-width: 340px;
  }

  .form__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .password-input {
    margin-left: 0;
  }
  .register__desc {
    padding: 25px 15px;
  }

  /* *** */

  .register__desc__title {
    font-size: 30px;
  }

  .register__desc__description {
    font-size: 18px;
  }

  .register__desc__text {
    font-size: 14px;
  }

  /* *** */

  .register__desc-title {
    display: block;
    font-size: 27px;
  }
  .register__desc-price {
    font-size: 40px;
  }
  .register__desc-bg {
    font-size: 26px;
  }
  .register__desc-text {
    font-size: 15px;
  }
  .tabs__wrapper {
    width: 280px;
    height: 40px;
  }
  .tab {
    font-size: 12px;
  }
  .tab:nth-child(1)::after {
    bottom: -17px;
  }
  .tab:nth-child(2)::after {
    bottom: -17px;
  }
  .shape {
    padding: 13px 20px 15px 20px;
    border-radius: 22px;
  }
  .form__wrapper > .form__row {
    margin-top: 10px;
  }
  .inp-form {
    height: 60px;
    font-size: 12px;
    width: 240px;
  }
  .img-mail {
    width: 15px;
    height: 12px;
  }
  .img-phone {
    width: 15px;
    height: 16px;
  }
  .email__img {
    left: 20px;
    top: 20px;
    width: 16px;
    height: 13px;
  }
  .password__img {
    left: 20px;
    top: 17px;
    width: 16px;
    height: 16px;
  }
  .password-control {
    width: 16px;
    right: 23px;
    height: 60px;
  }
  .currency__img {
    left: 20px;
    width: 16px;
    height: 14px;
  }
  .list-select {
    left: 50px;
    width: 170px;
  }
  .email-input {
    padding: 10px 30px;
  }
  .password-input {
    padding: 10px 30px;
  }

  .botom-pop {
    margin-top: 15px;
  }
  .form-btn {
    /* max-width: 240px; */
    height: 60px;
    font-size: 20px;
  }
  .list-select + .select2 .select2-selection--single {
    height: 12px;
  }
  .list-select + .select2 .select2-selection__rendered {
    font-size: 12px;
    padding-left: 30px;
  }

  .phone-prefix__code {
    font-size: 12px;
    left: 45px;
    top: 20px;
  }
  .phone-prefix__select + .select2-container--default {
    top: 12px;
  }
  .phone-prefix__select + .select2-container {
    left: 13px;
  }
  .phone-input {
    padding: 10px 3px 9px 100px;
  }
  .select2-container .select2-selection--single .select2-selection__rendered {
    width: 20px;
    height: 20px;
  }
  .phone-prefix__select
    + .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    left: 55px;
  }

  .tab.active::before,
  .tab.active::after {
    bottom: -22px;
  }

  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow
    b {
    background-size: 12px;
  }
}

@media screen and (max-width: 568px) {
  .register__bonus-text {
    line-height: 110%;
  }
  .register__desc-price {
    font-size: 40px;
}
.form__checkbox {
  font-size: 12px;
}
.botom-pop {
  margin-top: 10px;
}

.register__desc-title {
  font-size: 21px;
}

.bonuses {
  margin-bottom: 15px;
}
}

@media screen and (max-width: 390px) {
  .register__desc-title {
    font-size: 18px;
  }
  .register__bonus-text {
    font-size: 11px;
  }

  .register__wrapper {
    max-width: 295px;
    min-width: 100%;
  }
  .phone-input {
    padding: 10px 3px 9px 97px;
}

.register__wrapper {
  max-width:310px;
}

.register__desc-text {
  margin: 2px auto 15px;
}

}
@media screen and (width: 320px) {
  .register__wrapper {
    max-width: 280px;
  }

  /* *** */

  .register__desc__title {
    font-size: 28px;
  }

  .register__desc__description {
    font-size: 16px;
  }

  .register__desc__text {
    font-size: 14px;
  }

  /* *** */

}

@media screen and (max-height: 800px)  {
  .tabs__wrapper {
    max-height: 54px;
  }

  .form__row {
    margin-top:16px;
  }

  .botom-pop {
    margin-top: 12px;
    padding-bottom: 0px;
  }
}

/* 
@media screen and (max-width:927px) and (min-width:768px) and (max-height:430px) {

  .form__checkbox {
    font-size: 10px;
  }
  .inp-form {
    height: 32px;
    font-size: 12px;
  }

  .register__wrapper {
    min-width:unset;
    max-width:270px;
  }
  .register__desc {
    padding: 5px 10px;
  }

  .register__desc-title, .register__desc-price {
    font-size: 17px;
  }

  .register__desc-text {
    font-size: 10px;
    margin: 2px 0;
  }

  .tab {
    font-size: 14px;
  }

  .form-btn {
    max-height: 33px;
    font-size: 14px;
  }

  .shape {
    padding: 14px 17px 7px;
  }

  .password-input {
    margin-left: 0;
  }
  .register__desc-bg {
    font-size: 26px;
  }
  .tabs__wrapper {
    width: 280px;
    height: 34px;
  }
  .tab {
    font-size: 12px;
  }
  .tab:nth-child(1)::after {
    bottom: -17px;
  }
  .tab:nth-child(2)::after {
    bottom: -17px;
  }
  .shape {
    padding: 11px 17px 10px 17px;
    border-radius: 22px;
  }
  .form__wrapper > .form__row {
    margin-top: 5px;
  }
  .img-mail {
    width: 15px;
    height: 12px;
  }
  .img-phone {
    width: 15px;
    height: 16px;
  }
  .email__img {
    left: 20px;
    top: 20px;
    width: 16px;
    height: 13px;
  }
  .password__img {
    left: 20px;
    top: 17px;
    width: 16px;
    height: 16px;
  }
  .password-control {
    width: 16px;
    right: 23px;
  }
  .currency__img {
    left: 20px;
    width: 16px;
    height: 14px;
  }
  .list-select {
    left: 50px;
    width: 170px;
  }
  .email-input {
    padding: 10px 30px;
  }
  .password-input {
    padding: 10px 30px;
  }
  .list-select + .select2 .select2-selection--single {
    height: 12px;
  }
  .list-select + .select2 .select2-selection__rendered {
    font-size: 12px;
    padding-left: 30px;
  }

  .phone-prefix__code {
    font-size: 12px;
    left: 45px;
    top: 20px;
  }
  .phone-prefix__select + .select2-container--default {
    top: 12px;
  }
  .phone-prefix__select + .select2-container {
    left: 13px;
  }
  .phone-input {
    padding: 10px 3px 9px 100px;
  }
  .select2-container .select2-selection--single .select2-selection__rendered {
    width: 20px;
    height: 20px;
  }
  .phone-prefix__select
    + .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    left: 55px;
  }

  .tab.active::before,
  .tab.active::after {
    bottom: -22px;
  }

  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow
    b {
    background-size: 10px;
    top: 0;
  }

  .formError {
    padding-top: 7px;
    font-size: 10px;
    z-index: 100;
  }

  .form__row {
    margin-top: 10px;
  }

  .players-block__item {
    min-width: 100px;
  }

  .player-wrap {
    margin-right: 10px;
  }

} */
@media screen and (min-width: 876px) and (max-height:850px) {
  .register__desc {
    padding: 15px 25px;
  }

  .register__wrapper {
    margin-bottom: 10px;
  }

  .register__bonus--deposit {
    margin-top: 14px;
    margin-bottom: 15px;
  }
}