@import "/node_modules/reset-css/reset.css";
@import "/node_modules/select2/dist/css/select2.min.css";
@import "../../shared/css/arcticmodal.scss";
@import "../../shared/css/styles.scss";
@import "../../../shared/css/register.scss";
@import "../../shared/css/form.scss";
@import "../../shared/css/form_media.scss";

.logo {
	width: 240px;
	height: auto;
	margin-left: -120px;
}

.bonus_window .bonus_text {
	margin: 35px 30px 0 194px;
	width: 240px;
	font-weight: 700;
	font-size: 24px;
	line-height: 1.2;
}

.bonus_window .bonus_button {
	width: 180px;
}

@media screen and (max-width: 999px) {

	.main_title {
		max-width: 600px;
	}

}

@media screen and (max-width: 899px) {

	.bonus_window .bonus_text {
		margin: 0;
	}

}

@media screen and (max-width: 767px) {

	.logo {
		width: 170px;
		margin-left: -85px;
	}

	.bonus_window .bonus_text {
		width: 200px;
		font-size: 20px;
	}

	.bonus_window .bonus_button {
		width: 120px;
		font-size: 16px;
	}

}

@media screen and (max-width: 479px) {

	.bonus_window .bonus_text {
		width: 100px;
		font-size: 14px;
	}

	.bonus_window .bonus_button {
		width: 100px;
		font-size: 14px;
	}
	
}